import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const loadFilterValue = () => {
  const filterValue = JSON.parse(localStorage.getItem("filterValue"));
  return filterValue
    ? filterValue
    : {
      filter: false,
      city: "",
      state: "",
      country: "",
      pincode: "",
    };
};

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  filterValue: loadFilterValue(),
  users: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllUsers: (state) => state.users,
  getFilterValue: (state) => {
    return state.filterValue;
  },
};

const mutations = {
  setFilterValue: (state, payload) => {
    state.filterValue = payload;
    localStorage.setItem("filterValue", JSON.stringify(payload));
  },
  doNothing: (state) => state,
  setAllUsers: (state, payload) => (state.users = payload),
  addToUser: (state, payload) => state.users.unshift(payload),
  updateToUser: (state, updatedUser) => {
    state.users.forEach((user) => {
      if (user.id == updatedUser._id) {
        user.full_name = updatedUser.full_name;
        user.nick_name = updatedUser.nick_name;
        user.email = updatedUser.email;
        user.user_type = updatedUser.user_type;
        user.account_status = updatedUser.account_status;
        user.mobile = updatedUser.mobile;
        user.country = updatedUser.country;
        user.state = updatedUser.state;
        user.city = updatedUser.city;
        user.pincode = updatedUser.pincode;
        user.street = updatedUser.street;
        user.designation = updatedUser.designation;
        user.bio = updatedUser.bio;
        user.office_id = updatedUser.office_id;
        user.company_id = updatedUser.company_id;
        user.links = updatedUser.links;
        user.image = updatedUser.image;
      }
    });
  },
  updateToUserPassword: (state, updatedUser) => {
    state.users.forEach((user) => {
      if (user.id == updatedUser._id) {
        user.password = updatedUser.password;
        user.email = updatedUser.email
      }
    });
  },
  removeUser: (state, id) => {
    state.users = state.users.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value
  }
};

const actions = {
  updateFilterValue: ({ commit }, filterValue) => {
    commit("setFilterValue", filterValue);
  },

  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All User
   * @param {Number} data - Users Object
   * @returns Promise
   */
  async fetchAllUsers({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.users.getAllUsers+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllUsers", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update User data
   * @param {Object} data - Update  Data Object
   * @returns Promise
   */
  updateUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.updateUser+`${data.id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToUser", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description disable data
   * @param {Object} data - Update Data Object
   * @returns Promise
   */
  disableUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.disable+`${data.id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToUser", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description enable data
   * @param {Object} data - Update Data Object
   * @returns Promise
   */
  enableUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.disable+`${data.id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToUser", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Update User Profile
   * @param {Object} data - Update Profile Data Object
   * @returns Promise
   */
  updateUserPofile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.updateUserProfile, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToUser", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * @description Update User password
   * @param {Object} data - Update password Data Object
   * @returns Promise
   */
  updateUserPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.updateUserPassword, data)
        .then((res) => {
          console.log(res, 'res in update');
          if (res.data.success) {
            commit("updateToUserPassword", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description changePasswordInUser
   * @param {Object} data - Update password Data Object
   * @returns Promise
   */
  changePasswordInUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.changePassword, data)
        .then((res) => {
          console.log(res.data, 'data valid');
          if (res.data.success) {
            console.log(res.data,'data in store');
            commit("updateToUserPassword", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * @description Search User
   * @param {Number} data - User Object
   * @returns Promise
   */

  searchUser({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }

      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.users.searchUser+`?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllUsers", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove User
   * @param {Object} data - Remove User
   * @returns Promise
   */
  removeUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.users.removeUser+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeUser", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get User Info by ID
   * @param {Number} id - User ID
   * @returns Promise
   */
  fetchUserByID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.users.getUserByID+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Create New User by SUPER ADMIN
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.users.createUser, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToUser", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Updload File by User
   * @param {Object} data - Update user files
   * @returns Promise
   */
  uploadFileByUser({ commit }, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("dirType", data.type);
    formData.append("app_type", data.app_type);
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.users.uploadFile, formData)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Filter User
   * @param {Number} id - pincode , state, city
   * @returns Promise
   */
  async filterUser({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = {
          ...options,
          country: state.filterValue.country,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
        };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
          country: state.filterValue.country,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.users.filterUsers+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllUsers", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description On download button get data in excel sheet
   * @returns Promise
   */
  async getAllExcelSheetByDownload({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.users.downloadUsers)
        .then((res) => {
          resolve(res);
          commit("doNothing");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
